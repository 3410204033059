<template>
  <v-stepper-content :step="step" class="d-flex fill">
    <v-row>
      <v-col cols="12" md="4" class="info-content">
        <labelled-info label="Nome Fantasia">
          {{ detail.algodoeira.nome_fantasia || defaultEmptyData }}
        </labelled-info>
      </v-col>
      <v-col class="info-content">
        <labelled-info :label="$t('labels.gs1_plus_digit')">
          {{ detail.algodoeira.codigo_gs1_digito_extensao || defaultEmptyData }}
        </labelled-info>
      </v-col>
    </v-row>

    <v-card class="mt-5" color="grey lighten-3" elevation="0">
      <v-card-text class="py-10">
        <div class="d-flex flex-column justify-center align-center fill">
          <v-icon color="accept" size="56">mdi-checkbox-marked-circle</v-icon>
          <h1 class="mt-7 primary-text--text text-responsible">
            Informações registradas com sucesso!
          </h1>
          <btn-link class="mt-3" @click="$router.push('/')"
            >Retornar aos Convites ABR-UBA
          </btn-link>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="my-5 pb-7" color="grey lighten-3" elevation="0">
      <v-card-text class="d-flex">
        <div class="d-flex flex-column programs-content">
          <h3 class="mb-5 primary-text--text text-responsible">
            Programa ABR-UBA
          </h3>
          <v-card
            class="d-flex flex-column justify-space-between info-main-content"
            elevation="0"
          >
            <v-card-text class="py-7 wish-participate-content">
              <p class="ma-0 primary-text--text">
                <strong>Deseja participar do Programa ABR-UBA?</strong>
              </p>
              <p class="secondary-text--text">{{ accepted ? "Sim" : "Não" }}</p>
            </v-card-text>
            <v-card-actions class="mb-4">
              <v-btn
                :disabled="!accepted || !file"
                color="primary"
                block
                @click="openTerm"
                class="donwload-button"
                ><v-icon class="mr-3">mdi-cloud-download</v-icon>Download do
                Termo de Adesão do ABR-UBA
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-stepper-content>
</template>

<script>
import LabelledInfo from "@/modules/core/views/components/LabelledInfo";
import BtnLink from "@/modules/core/views/components/BtnLink";

import { mapActions } from "vuex";

export default {
  name: "StepConfirmation",

  props: {
    detail: {
      type: Object,
      required: true
    },

    step: {
      type: Number,
      required: true
    },

    accepted: {
      type: Boolean,
      required: false
    }
  },

  components: {
    LabelledInfo,
    BtnLink
  },

  data: () => ({
    defaultEmptyData: "Não registrado",
    file: null
  }),

  mounted() {
    if (this.accepted) {
      this.getTerms();
    }
  },

  methods: {
    ...mapActions(["toggleSnackbar"]),
    ...mapActions("inviteUba", ["getTermUBA"]),

    openTerm() {
      window.open(this.file, "_blank");
    },

    async getTerms() {
      try {
        const id = parseInt(this.$route.params.id, 10);
        const response = await this.getTermUBA(id);
        if (response.file) {
          this.file = response.file;
        }

        this.toggleSnackbar({
          text: response.detail ?? this.$t("text.term_generated_success"),
          type: "success"
        });
      } catch (error) {
        this.toggleSnackbar({
          text: error.response?.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.programs-content {
  justify-content: center;
  gap: 16px;
  padding: 0 8px;

  .donwload-button {
    gap: 4px;
  }
}
@media screen and (max-width: 960px) {
  .info-content {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .text-responsible {
    text-align: center;
  }

  .info-main-content {
    padding: 0 8px;
  }

  .programs-content {
    flex-direction: column;

    .wish-participate-content {
      text-align: center;
    }

    .donwload-button {
      white-space: normal;
      height: auto !important;
      padding: 12px !important;
      width: 100%;

      ::v-deep .v-btn__content {
        width: 100%;
      }
    }
  }
}
</style>
